/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import classes from "./Login.module.scss";

type InputProps = {
  length?: number;
  // OTP: string[];
  setOTP: React.Dispatch<React.SetStateAction<string[]>>;
};

const OTPInput = ({ length = 6, setOTP }: InputProps) => {
  useEffect(() => {
    try {
      const inputs = document.querySelectorAll("#otp-input input") as NodeListOf<HTMLInputElement>;

      const updateInput = () => {
        // const inputValue = Array.from(inputs).reduce((otp, input) => {
        //   otp += (input?.value?.length) ? input.value : ' ';
        //   return otp;
        // }, "");
        const otp = Array.from(inputs)?.map((ele) => ele?.value || "");
        if (otp?.length === length) {
          setOTP(otp);
        }
      };
      if (inputs) {
        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];

          input.addEventListener("input", () => {
            // handling normal input
            if (input.value.length === 1 && i + 1 < inputs.length) {
              inputs[i + 1].focus();
            }

            // if a value is pasted, put each character to each of the next input
            if (input.value.length > 1) {
              // sanitize input
              if (Number.isNaN(input.value)) {
                input.value = "";
                updateInput();
                return;
              }

              // split characters to array
              const chars = input.value.split("");

              for (let pos = 0; pos < chars.length; pos++) {
                // if length exceeded the number of inputs, stop
                if (pos + i >= inputs.length) break;

                // paste value
                const targetInput = inputs[pos + i];
                targetInput.value = chars[pos];
              }

              // focus the input next to the last pasted character
              const focus_index = Math.min(inputs.length - 1, i + chars.length);
              inputs[focus_index].focus();
            }
            updateInput();
          });

          input.addEventListener("keydown", (e) => {
            // backspace button
            if (e.keyCode === 8 && input.value === "" && i !== 0) {
              if (inputs) {
                // shift next values towards the left
                for (let pos = i; pos < inputs.length - 1; pos++) {
                  inputs[pos].value = inputs[pos + 1].value;
                }

                // clear previous box and focus on it
                inputs[i - 1].value = "";
                inputs[i - 1].focus();
                updateInput();
              }
              return;
            }

            // delete button
            if (e.keyCode === 46 && i !== inputs.length - 1) {
              if (inputs) {
                // shift next values towards the left
                for (let pos = i; pos < inputs.length - 1; pos++) {
                  inputs[pos].value = inputs[pos + 1].value;
                }

                // clear the last box
                inputs[inputs.length - 1].value = "";
                input.select();
                e.preventDefault();
                updateInput();
              }
              return;
            }

            // left button
            if (e.keyCode === 37) {
              if (i > 0) {
                e.preventDefault();
                if (inputs[i - 1]) {
                  inputs[i - 1].focus();
                  inputs[i - 1].select();
                }
              }
              return;
            }

            // right button
            if (e.keyCode === 39) {
              if (i + 1 < inputs.length) {
                e.preventDefault();
                if (inputs[i + 1]) {
                  inputs[i + 1].focus();
                  inputs[i + 1].select();
                }
              }
            }
          });
        }
      }
    } catch (err) {
      /* empty */
    }
  }, []);

  return (
    <div id="otp-input" className={classes.otp_input_fields}>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          maxLength={1}
          type="number"
          step="1"
          min="0"
          max="9"
          autoComplete="no"
          pattern="\d*"
          className={classes.otp_input}
          style={{ marginRight: index === length - 1 ? "0" : "10px" }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
