import { getToken, jsonToURI } from "../../utils/general";
import { interceptorFunction } from "../interceptors";
const interceptor = interceptorFunction();

export const getMasterData = () => interceptor.get(`/master`);

export const getProducts = (data: any) =>
  interceptor.get(`/itcu-products?data=${jsonToURI(data)}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
